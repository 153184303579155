import { useQuery } from 'apollo-client'
import { getAnalyticsContext } from 'analytics'

import type { RecommendationsProductFilter, UserGender } from 'typings/graphql'

import recommendedForYouQuery from './graph/recommendedForYou.graphql'


type UseRecommendedForYouProps = {
  gender?: UserGender
  limit?: number
  placement: string
  skip?: boolean
  productsFilter?: RecommendationsProductFilter
}

export const useRecommendedForYou = (props: UseRecommendedForYouProps) => {
  const { gender, limit, placement, skip, productsFilter } = props

  const { page, pageType } = getAnalyticsContext()

  const input = {
    productsGender: gender,
    ...(productsFilter && { productsFilter }),
    limit,
    metadata: {
      page,
      pageType,
      placement,
    },
  }

  const { data, isFetching } = useQuery(recommendedForYouQuery, {
    variables: { input },
    skip,
    ssr: false,
  })

  return {
    data: data?.recommendationsForYou?.data,
    isFetching,
  }
}
