import { useQuery } from 'apollo-client'
import { getAnalyticsContext } from 'analytics'

import type { UserGender } from 'typings/graphql'

import bestsellersQuery from './graph/bestsellers.graphql'


type UseBestsellersProps = {
  gender?: UserGender
  limit?: number
  skip?: boolean
}

export const useBestsellers = (props: UseBestsellersProps = {}) => {
  const { gender, limit, skip } = props

  const { page, pageType } = getAnalyticsContext()

  const { data, isFetching } = useQuery(bestsellersQuery, {
    variables: {
      input: {
        gender,
        limit,
        metadata: {
          page,
          pageType,
          placement: 'Bestsellers',
        },
      },
    },
    skip,
    ssr: false,
  })

  return {
    data: data?.recommendationsSellingFast?.data,
    isFetching,
  }
}

