import localStorage from 'local-storage'
import { constants } from 'helpers'
import type { QuizAnswer } from 'typings/graphql'


type QuizResult = Array<QuizAnswer>

export const restoreFlowSteps = () => {
  if (__SERVER__) {
    return {}
  }
  return localStorage.getItem(constants.localStorageNames.quizFlowSteps) || {}
}

export const getResultFromFlow = (step, flowSteps, linkedSteps, result = [], index = 1): QuizResult => {
  const stepAnswer = step?.answers?.find(({ id }) => id === flowSteps[step.id])

  const nexStepId = stepAnswer?.nextQuizQuestionId
  const nextStep = nexStepId && linkedSteps.find(({ id }) => id === nexStepId)

  result.push({
    name: step?.name,
    question: index,
    answers: [ stepAnswer?.answer ],
  })

  return nextStep ? getResultFromFlow(nextStep, flowSteps, linkedSteps, result, index + 1) : result
}
