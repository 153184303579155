import { useQuery } from 'apollo-client'

import unratedProductQuery from './graph/unratedProduct.graphql'


type UseUnratedProductParams = {
  skip?: boolean
}

export const useUnratedProduct = (params: UseUnratedProductParams = {}) => {
  const { skip } = params

  const { data, isFetching } = useQuery(unratedProductQuery, {
    ssr: false,
    skip,
  })

  const product = data?.recommendations?.data?.__typename === 'HistoryRecommendationData'
    ? data.recommendations.data.unratedProducts?.[0]
    : null

  return {
    isFetching,
    product,
    recommenderName: data?.recommendations?.data?.recommenderName,
  }
}

