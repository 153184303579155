import { useMemo } from 'react'


const GENDER_STEP_NAME = 'gender'

type useGenderOfQuizFlowProps = {
  flowSteps: QuizPage.Steps
  linkedSteps: QuizPage.LinkedQuestions
}

const useGenderOfQuizFlow = (props: useGenderOfQuizFlowProps) => {
  const { flowSteps, linkedSteps } = props

  return useMemo(() => {
    const genderStep = linkedSteps.find((linkedStep) => linkedStep.name === GENDER_STEP_NAME)
    const genderAnswerId = flowSteps[genderStep?.id]
    const genderAnswerValue = genderStep?.answers.find(({ id }) => id === genderAnswerId)

    return {
      genderId: genderStep?.id,
      genderValue: genderAnswerValue?.answer,
    }
  }, [ flowSteps, linkedSteps ])
}

export default useGenderOfQuizFlow
