import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { useQuery } from 'apollo-client'
import { getAnalyticsContext } from 'analytics'
import { constants } from 'helpers'

import similarRecommendationsQuery from './graph/similarRecommendations.graphql'


type UseRecommendationsProps = {
  productUid: string
  isDrift?: boolean
  skip?: boolean
  limit?: number
}

export const useSimilarRecommendations = ({ isDrift = false, productUid, skip, limit = 6 }: UseRecommendationsProps) => {
  const areConstructorRecommendationsEnabled = useFeatureIsOn(constants.features.constructorRecommendations)

  const { page, pageType } = getAnalyticsContext()

  const { data, isFetching } = useQuery(similarRecommendationsQuery, {
    variables: {
      areConstructorRecommendationsEnabled,
      input: {
        limit,
        productUid,
        metadata: {
          page,
          pageType,
          placement: 'recommendations section',
        },
      },
      isDrift,
    },
    skip: skip || !productUid,
    ssr: false,
  })

  return {
    data: data?.recommendationsSimilar?.data,
    isFetching,
  }
}
