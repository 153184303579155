import { useMemo } from 'react'
import { useQuery } from 'apollo-client'

import type { ProfileRecommendationData, UserGender, VoteStatus } from 'typings/graphql'

import quizResultQuery, { type QuizResultVariables, type QuizResultPayload } from './graph/quizResult.graphql'


export type UseDiscoveryQuizResultParams = {
  withRepresentation?: boolean
  historyProductsInput?: QuizResultVariables['historyProductsInput']
  profileRecommendationInput?: Required<QuizResultVariables['profileRecommendationInput']>
  skip?: boolean
  ssr?: boolean
}

type UseDiscoveryQuizResult = {
  isFetching: boolean
  hasScentProfile: boolean
  quizResult: QuizResultPayload['quizResult']
  quizGender?: UserGender
  quizRecommendations?: {
    type: ProfileRecommendationData['type']
    recommenderName: ProfileRecommendationData['recommenderName']
    description?: string
    products: Array<ProductFragment.Detailed & {
      userVote: VoteStatus
      fragranceFamilies: { name: string }[]
    }>
  }
  historyRecommendations?: QuizResultPayload['historyRecommendations']['data']
}

export const useDiscoveryQuizResult = (params: UseDiscoveryQuizResultParams = {}): UseDiscoveryQuizResult => {
  const { profileRecommendationInput, withRepresentation = true, historyProductsInput, skip, ssr } = params

  const withHistoryRecs = Boolean(historyProductsInput)
  const withProfileRecs = Boolean(profileRecommendationInput)

  const { data, isFetching } = useQuery(quizResultQuery, {
    variables: {
      profileRecommendationInput,
      withProfileRecs,
      withRepresentation,
      historyProductsInput,
      withHistoryRecs,
    },
    skip,
    ssr,
    fetchPolicy: 'cache-first',
  })

  return useMemo(() => {
    const { quizResult, recommendations, historyRecommendations } = data || {}

    const hasScentProfile = Boolean(quizResult?.representation)

    let result: UseDiscoveryQuizResult = {
      quizResult,
      hasScentProfile,
      isFetching,
    }

    if (hasScentProfile) {
      const genderResult = quizResult?.result?.find(({ name }) => name === 'gender')

      result.quizGender = genderResult?.answers[0] === 'female' ? 'FEMALE' : 'MALE'
    }

    if (historyRecommendations?.data.__typename === 'HistoryRecommendationData' && historyRecommendations?.data?.products.length) {
      result.historyRecommendations = historyRecommendations.data
    }

    if (recommendations?.data?.__typename === 'ProfileRecommendationData') {
      const { classes, type, recommenderName } = recommendations?.data

      // we need to combine classes sequentially 1, 2, 3, 1, 2, 3, 1, 3, etc.
      // get maximum number of products in classes
      const structure = classes.reduce((result, { products }) => {
        products.forEach((product, index) => {
          if (result[index]) {
            result[index].push(product)
          }
          else {
            result[index] = [ product ]
          }
        })

        return result
      }, {})

      // flat lists
      const products = Object.keys(structure).reduce((result, key) => {
        return result.concat(structure[key])
      }, [])

      result.quizRecommendations = {
        type,
        description: quizResult?.representation?.scentRecommendationText,
        recommenderName,
        products,
      }
    }

    return result
  }, [ data, isFetching ])
}
