import { useCallback } from 'react'
import { constants } from 'helpers'
import localStorage from 'local-storage'
import { useMutation, useApolloClient } from 'apollo-client'

import { useGenderOfQuizFlow, useQuizSteps, useValidityOfQuizFlow } from '.'

import { getResultFromFlow, restoreFlowSteps } from './helpers'
import createQuizResult from './createQuizResult.graphql'


type QuizSteps = Record<string, string>
type UseQuizResultPageParams = {
  flowSteps?: QuizSteps
}

const clearFlowSteps = () => localStorage.removeItem(constants.localStorageNames.quizFlowSteps)
const storeFlowSteps = (result) => localStorage.setItem(constants.localStorageNames.quizFlowSteps, result)

const useQuizResult = (params: UseQuizResultPageParams = {}) => {
  const { flowSteps = restoreFlowSteps() } = params

  const apolloClient = useApolloClient()
  const [ createResult, { isFetching, called } ] = useMutation(createQuizResult, {
    fetchPolicy: 'no-cache',
  })
  const { steps, isFetching: isStepsFetching } = useQuizSteps()

  const { genderId } = useGenderOfQuizFlow({ flowSteps, linkedSteps: steps })

  const headStep = steps?.find(({ id }) => id === genderId)
  const tailStep = steps?.find(({ id }) => id === flowSteps.step)

  const isValid = useValidityOfQuizFlow({ entryStep: tailStep, flowSteps, linkedSteps: steps, isCompleted: true })

  const sendResult = useCallback(async () => {
    const result = getResultFromFlow(headStep, flowSteps, steps)

    await createResult({
      variables: {
        input: {
          result,
          type: 'DISCOVERY',
        },
      },
    })

    clearFlowSteps()

    apolloClient.cache.evict({ id: 'ROOT_QUERY', fieldName: 'quizResult' })

    apolloClient.cache.modify({
      id: 'PersonalInfo:{}',
      fields: {
        analyticsMetadata: ((value) => ({
          ...value,
          hasScentProfile: true,
        })),
      },
    })
  }, [ headStep, flowSteps, steps, createResult, apolloClient.cache ])

  return {
    isValid,
    sendResult,
    storeFlowSteps,
    isCalled: called,
    isFetching: isFetching || isStepsFetching,
  }
}

export default useQuizResult
